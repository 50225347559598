import axios, { AxiosError } from 'axios'
import { ChargebeeCustomer, ChargebeeSubscription, EasyLicenseInformation, Seat, SeatUser, Subscription, SubscriptionEnvelop } from '../common/interfaces';
import { APIM } from '../common/constants';

export class SubscriptionService {
  constructor(access_token?: string) {
    if (access_token !== undefined && access_token !== null) {
      if (access_token.startsWith("Bearer ")) {
        this.access_token = access_token;
      }
      else {
        this.access_token = "Bearer " + access_token;
      }
    }
  }

  access_token: string;

  getSubscriptionStatus(status_id: number): string {
    switch (status_id) {
      case 0:
        return "Unknown";
      case 1:
        return "Pending Confirmation";
      case 2:
        return "Pending Activation";
      case 3:
        return "Active";
      case 4:
        return "Suspended";
      case 5:
        return "Cancelled";
      case 6:
        return "Purchased";
      default:
        return "Unknown";
    }
  }

  getSubscriptionProductTitle(offer_id: string): string {
    switch (offer_id) {
      case "easycallreport_dev-preview":
      case "easycallreport_dev":
      case "easycallreport-preview":
      case "easycallreport":
      case "EasyCallReport-Basic-CHF-Yearly":
      case "EasyCallReport-Basic-EUR-Yearly":
      case "EasyCallReport-Pro-CHF-Yearly":
      case "EasyCallReport-Pro-EUR-Yearly":
      case "EasyCallReport-Enterprise-CHF-Yearly":
      case "EasyCallReport-Enterprise-EUR-Yearly":
      case "Easy-Call-Report-NFR-CHF-Monthly":
      case "Easy-Call-Report-NFR-EUR-Monthly":
      case "EasyCallReport-NFR-CHF-Monthly":
      case "EasyCallReport-NFR-EUR-Monthly":
      case "Easy-Call-Report-Basic-CHF-Yearly":
      case "Easy-Call-Report-Pro-CHF-Yearly":
      case "Easy-Call-Report-Enterprise-CHF-Yearly":
      case "Easy-Call-Report-Basic-EUR-Yearly":
      case "Easy-Call-Report-Pro-EUR-Yearly":
      case "Easy-Call-Report-Enterprise-EUR-Yearly":
        return "Easy Call Report";
      case "TeamsEasyCallReport-Standard-CHF-Yearly":
        return "Easy Call Report (deprecated)";
      case "TeamsEasyCallReport-Standard-EUR-Yearly":
        return "Easy Call Report (deprecated)";
      case "easydirectory_saas_uat-preview":
        return "Easy Directory";
      default:
        return "Unknown";
    }
  }

  getSubscriptionProductPlan(plan_id: string): string {
    switch (plan_id) {
      case "ecr_basic":
      case "basic":
      case "basic_plan":
      case "EasyCallReport-Basic-CHF-Yearly":
      case "Easy-Call-Report-Basic-CHF-Yearly":
      case "EasyCallReport-Basic-EUR-Yearly":
      case "Easy-Call-Report-Basic-EUR-Yearly":
      case "TeamsEasyCallReport-Standard-CHF-Yearly":
      case "Teams-Easy-CallReport-Standard-CHF-Yearly":
      case "TeamsEasyCallReport-Standard-EUR-Yearly":
      case "Teams-Easy-CallReport-Standard-EUR-Yearly":
        return "Basic";
      case "pro":
      case "pro_plan":
      case "EasyCallReport-Pro-CHF-Yearly":
      case "Easy-Call-Report-Pro-CHF-Yearly":
      case "EasyCallReport-Pro-EUR-Yearly":
      case "Easy-Call-Report-Pro-EUR-Yearly":
        return "Professional";
      case "Easy-Call-Report-NFR-CHF-Monthly":
      case "Easy-Call-Report-NFR-EUR-Monthly":
      case "EasyCallReport-NFR-CHF-Monthly":
      case "EasyCallReport-NFR-EUR-Monthly":
        return "NFR";
      case "enterprise":
      case "enterprise_plan":
      case "EasyCallReport-Enterprise-CHF-Yearly":
      case "Easy-Call-Report-Enterprise-CHF-Yearly":
      case "EasyCallReport-Enterprise-EUR-Yearly":
      case "Easy-Call-Report-Enterprise-EUR-Yearly":
        return "Enterprise";
      case "test_mini":
      case "mini":
        return "Mini"
      default:
        return "Unknown";
    }
  }

  checkDeprecatedEcr(plan_id: string): boolean {
    switch (plan_id) {
      case "TeamsEasyCallReport-Standard-CHF-Yearly":
      case "TeamsEasyCallReport-Standard-EUR-Yearly":
        return true;
      default:
        return false;
    }
  }
  async getOldEasyDirectorySubscription(): Promise<SubscriptionEnvelop | null> {
    try {
      const response = await fetch(`${APIM}easydirectory/migration/subscription`, {
        method: 'GET',
        headers: {
          Authorization: this.access_token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      if (!response.ok) {
        console.error('Failed to fetch old EasyDirectory subscription:', {
          status: response.status,
          statusText: response.statusText,
        })
        return null
      }

      const data = await response.json()
      return data as SubscriptionEnvelop
    } catch (error) {
      console.error('Error fetching old EasyDirectory subscription:', error)
      return null
    }
  }

  async getOldEasyDirectoryPersonalTags(): Promise<string[] | null> {
    try {
      const response = await fetch(`${APIM}easydirectory/migration/tags`, {
        method: 'GET',
        headers: {
          Authorization: this.access_token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      if (!response.ok) {
        console.error('Failed to fetch old easy directory personal tags:', {
          status: response.status,
          statusText: response.statusText,
        })
        return null
      }

      const data = await response.json()
      return data as string[]
    } catch (error) {
      console.error('Error fetching old EasyDirectory subscription:', error)
      return null
    }
  }

  async migrateSubscription(subscription: SubscriptionEnvelop): Promise<boolean> {
    try {
      const response = await fetch(`${APIM}subscriptions/create`, {
        method: 'POST',
        headers: {
          Authorization: this.access_token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(subscription),
      })

      if (!response.ok) {
        console.error('Failed to migrate subscription')
        return false
      }

      return true
    } catch (error) {
      console.error('Error migrating subscription:', error)
      return false
    }
  }

  async migrateEasyDirectory(migrateTags: boolean): Promise<boolean> {
    try {
      const response = await fetch(`${APIM}easydirectory/migrate`, {
        method: 'POST',
        headers: {
          Authorization: this.access_token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ migrateTags }),
      })

      if (!response.ok) {
        console.error('Failed to migrate Tenant')
        return false
      }

      return true
    } catch (error) {
      console.error('Error migrating tenant:', error)
      return false
    }
  }

  async getSubscriptionFromToken(store_token: string): Promise<Subscription> {
    const response = await fetch(
      APIM + 'subscriptions/landing?token=' + encodeURIComponent(store_token),
      {
        method: 'GET',
      },
    ).catch(console.log)

    if (response) {
      const res = await response.json()

      return (res as Subscription) ?? null
    }

    return null
  }

  async postNewSubscription(
    store_token: string,
    subscription: Subscription,
  ): Promise<Subscription> {
    const response = await fetch(APIM + 'subscriptions', {
      method: 'POST',
      headers: {
        Authorization: store_token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(subscription),
    }).catch(console.log)

    if (response) {
      const res = await response.json()

      return (res as Subscription) ?? null
    }

    return null
  }
  async patchSubscription(subscriptionId: string, subscription: Subscription): Promise<Subscription> {
    const response = await fetch(APIM + 'subscriptions/' + subscriptionId, {
      method: 'PATCH',
      headers: {
        'Authorization': this.access_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(subscription)
    }).catch(console.log)

    if (response) {
      const res = await response.json();

      return res as Subscription ?? null;
    }

    return null;
  }

  async getSubscriptions(): Promise<Subscription[]> {
    const response = await fetch(APIM + 'subscriptions', {
      method: 'GET',
      headers: {
        'Authorization': this.access_token,
        'Content-Type': 'application/json'
      }
    }).catch(console.log)

    if (response) {
      const res = await response.json();

      return res ?? null;
    }

    return null;
  }

  IsReseller = (subscriptions: Subscription[]) => {
    let isReseller = false;

    subscriptions.forEach((subscription: Subscription) => {
      if (subscription.offer_id.includes("Easy-Call-Report-NFR") === true || subscription.offer_id.includes("EasyCallReport-NFR") === true || (subscription?.customer?.isReseller === true && subscription?.customer?.tenantId === subscription?.tenant_id)) {
        isReseller = true;
      }
    });

    return isReseller;
  }

  async getResellerSubscriptions(resellerId: string): Promise<Subscription[]> {
    let response;

    if (resellerId !== undefined && resellerId !== null) {
      response = await fetch(APIM + 'reseller/subscriptions', {
        method: 'GET',
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      }).catch(console.log)
    } else {
      response = await fetch(APIM + 'subscriptions', {
        method: 'GET',
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      }).catch(console.log)
    }


    if (response) {
      const res = await response.json();

      return res ?? null;
    }

    return null;
  }

  async assignResellerSubscription(subscriptionId: string, tenantId: string): Promise<Subscription> {

    if (subscriptionId === undefined || subscriptionId === null || subscriptionId === "" || tenantId === undefined || tenantId === null || tenantId === "") {
      throw new Error("Missing Parameters");
    }

    try {
      const response = await axios.post(APIM + 'reseller/subscriptions/' + subscriptionId + '/assign', {
        tenant: tenantId
      },
        {
          headers: {
            'Authorization': this.access_token,
            'Content-Type': 'application/json'
          }
        })

      return response.data;
    } catch (e: unknown) {
      throw new AxiosError(e as string);
    }
  }

  async getSubscription(subscriptionId: string): Promise<Subscription> {
    try {
      const response = await axios.get(APIM + 'subscriptions/' + subscriptionId, {
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      })

      return response.data;
    } catch (e: unknown) {
      throw new AxiosError(e as string);
    }
  }

  getEasyCallReportSeatCount(subscriptions: Subscription[]): number {
    let seatCount = 0;

    subscriptions.forEach((subscription) => {
      if (subscription.state === 3) {
        if (subscription.offer_id.includes("easycallreport") === true) {
          seatCount = seatCount + (subscription.seatQuantity * 5); // Multiply with 5 for MS licenses, because we offer 5 call queues per seat
        } else if (subscription.offer_id.includes("Easy-Call-Report-NFR") === true || subscription.offer_id.includes("EasyCallReport-NFR") === true) {
          seatCount = seatCount + 5; // Reseller are getting 5 licenses
        }
        else if (subscription.offer_id.includes("EasyCallReport") === true) {
          seatCount = seatCount + subscription.seatQuantity;
        }
      }
    });

    return seatCount;
  }

  getEasyDirectorySeatCount(subscriptions: Subscription[]): number {
    let seatCount = 0;

    subscriptions.forEach((subscription) => {
      if (subscription.state === 3) {
        if (subscription.offer_id.includes("easydirectory") === true) {
          seatCount = seatCount + subscription.seatQuantity;
        } else if (subscription.offer_id.includes("EasyDirectory") === true) {
          seatCount = seatCount + subscription.seatQuantity;
        }
      }
    });

    return seatCount;
  }

  async getEasyDirectorySeats(subscriptionId): Promise<Seat[]> {
    try {
      const response = await axios.get(APIM + 'subscriptions/subscriptions/' + subscriptionId + '/seats', {
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      })

      return response.data;
    } catch (e: unknown) {
      throw new AxiosError(e as string);
    }
  }

  async ReserveSeatByEmail(subscriptionId: string, body: SeatUser): Promise<any> {
    try {
      const response = await axios.post(APIM + 'subscriptions/saas/subscriptions/' + subscriptionId + '/seats/reserve', body, {
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      })

      return response.data;
    } catch (e: unknown) {
      throw new AxiosError(e as string);
    }
  }

  isEasyCallReportLicensed(subscriptions: Subscription[]): boolean {
    let isLicensed = false;

    subscriptions.forEach((subscription) => {
      if (subscription.state === 3) {
        if (subscription.offer_id.toLocaleLowerCase().includes("easycallreport") || subscription.offer_id.toLocaleLowerCase().includes("easy-call-report")) {
          isLicensed = true;
        }
      }
    });

    return isLicensed;
  }

  isEasyDirectoryLicensed(subscriptions: Subscription[]): boolean {
    let isLicensed = false;

    subscriptions.forEach((subscription) => {
      if (subscription.state === 3) {
        if (subscription.offer_id.toLocaleLowerCase().includes("easydirectory") || subscription.offer_id.toLocaleLowerCase().includes("easy-directory")) {
          isLicensed = true;
        }
      }
    });

    return isLicensed;
  }

  getEasyDirectoryLicenseInfo(subscriptions: Subscription[]): EasyLicenseInformation {
    const licenseInfo: EasyLicenseInformation = {
      isLicensed: false,
      seatCount: 0,
      planType: ''
    };

    const seatCount = this.getEasyDirectorySeatCount(subscriptions);

    subscriptions.forEach((subscription) => {
      if (subscription.state === 3) {
        if (subscription.offer_id.toLocaleLowerCase().includes("easydirectory") || subscription.offer_id.toLocaleLowerCase().includes("easy-directory")) {
          licenseInfo.isLicensed = true;
          licenseInfo.planType = "";
          licenseInfo.seatCount = seatCount;
        }
      }
    });

    return licenseInfo;
  }

  getEasyCallReportLicenseInfo(subscriptions: Subscription[]): EasyLicenseInformation {
    const licenseInfo: EasyLicenseInformation = {
      isLicensed: false,
      seatCount: 0,
      planType: ''
    };

    const seatCount = this.getEasyCallReportSeatCount(subscriptions);

    subscriptions.forEach((subscription) => {
      if (subscription.state === 3) {
        if (
          subscription.offer_id.includes("easycallreport") === true ||
          subscription.offer_id.includes("EasyCallReport") ||
          subscription.offer_id.includes("Easy-Call-Report")
        ) {
          if (
            subscription.plan_id.includes("pro") ||
            subscription.plan_id.includes("enterprise") ||
            subscription.plan_id.includes("EasyCallReport-Pro") ||
            subscription.plan_id.includes("EasyCallReport-Enterprise") ||
            subscription.plan_id.includes("EasyCallReport-NFR")
          ) {
            licenseInfo.isLicensed = true;
            licenseInfo.planType = this.getSubscriptionProductPlan(subscription.plan_id);
            licenseInfo.seatCount = seatCount;
          }
        }
      }
    });

    return licenseInfo;
  }

  async getChargebeeCustomer(): Promise<ChargebeeCustomer> {
    try {
      const response = await axios.get(APIM + 'licenses/customers/', {
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      })
      return response.data.customer;
    } catch (e: unknown) {
      throw new AxiosError(e as string);
    }
  }

  async getChargebeeSubscriptions(customerId: string): Promise<ChargebeeSubscription[]> {
    try {
      const response = await axios.get(APIM + 'licenses/customers/' + customerId + '/subscriptions', {
        headers: {
          'Authorization': this.access_token,
          'Content-Type': 'application/json'
        }
      })

      return response.data.list;
    } catch (e: unknown) {
      throw new AxiosError(e as string);
    }
  }

  IsEasyCallReportProLicensed(subscriptions: Subscription[]): boolean {
    try {
      let isLicensed = false

      subscriptions.forEach((subscription) => {
        if (subscription.state === 3) {
          if (
            subscription.offer_id.includes("easycallreport") === true ||
            subscription.offer_id.includes("EasyCallReport") ||
            subscription.offer_id.includes("Easy-Call-Report")
          ) {
            if (
              subscription.plan_id.includes("pro") ||
              subscription.plan_id.includes("enterprise") ||
              subscription.plan_id.includes("EasyCallReport-Pro") ||
              subscription.plan_id.includes("EasyCallReport-Enterprise") ||
              subscription.plan_id.includes("EasyCallReport-NFR")
            ) {
              isLicensed = true
            }
          }
        }
      })

      return isLicensed
    } catch (e) {
      console.log(e)
      return false
    }
  }
  getExpirationDate(date: Date) {
    if (date !== undefined && date !== null) {
      const dateToFormat = new Date(date);
      return new Intl.DateTimeFormat(navigator.languages[0], { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dateToFormat);
    }
    return "";
  }
}

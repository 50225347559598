import { useState, useEffect, useContext } from 'react'
import {
  CCard,
  CCardBody,
  CButton,
  CAlert,
  CSpinner,
  CTable,
  CTableHead,
  CTableBody,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CCardTitle,
  CCardText,
  CImage,
} from '@coreui/react'
import { ProductConfiguration } from '../../../common/constants'
import { ConfigurationService } from '../../../services/ConfigurationService'
import { StepProps } from '../types'
import { EasyContext } from '../../../components/context/EasyContext'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

import EasyDirectoryLogo from '../../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'
import { AppRoleAssignments } from '../../../common/interfaces'

const REQUIRED_APP_ROLES = [
  'ff74d97f-43af-4b68-9f2a-b77ee6968c5d',  // User.Read.All
  '242b9d9e-ed24-4d09-9a52-f43769beb9d4',  // Presence.Read.All
  'd56682ec-c09e-4743-aaf4-1a3aac4caa21',  // MailboxSettings.Read
  'a154be20-db9c-4678-8ab7-66f6cc099a59',  // User.Read
  '9c7a330d-35b3-4aa1-963d-cb2b9f927841',  // Contacts.Read.Shared
  '87f447af-9fa4-4c32-9dfa-4a57a73d18ce'   // Contacts.Read
];

const hasAllAppRoleAssignments = (appId: string, response: AppRoleAssignments) => {
  const roles = response.value;

  if (ProductConfiguration.EasyDirectory.ApplicationId === appId) {
    return REQUIRED_APP_ROLES.every((roleId) => roles.some((item) => item.appRoleId === roleId))
  }
  
  return false;
}

const ConsentStep: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [consentGranted, setConsentGranted] = useState(false)
  const [validationStatus, setValidationStatus] = useState<
    'idle' | 'checking' | 'success' | 'error'
  >('idle')

  const { accounts } = useMsal();

  const { accessToken } = useContext(EasyContext)
  const configurationService = new ConfigurationService(accessToken)

  const REDIRECTURL = `${ProductConfiguration.EasyDirectory.RedirectUrl}popup-close.html`

  const appRoleRequest = {
    scopes: ["Application.Read.All"],
    account: accounts[0]
  };

  const { acquireToken, result, error } = useMsalAuthentication(InteractionType.Silent, {
    ...appRoleRequest
  });

  useEffect(() => {
    if (result) {
      configurationService
        .CheckAppRoleAssignments(ProductConfiguration.EasyDirectory.ApplicationId, result.accessToken)
        .then((response) => {
          if (hasAllAppRoleAssignments(ProductConfiguration.EasyDirectory.ApplicationId, response)) {
            setConsentGranted(true)
          }
        })
        .catch((error) => {
          console.error('Error checking initial consent:', error)
          setConsentGranted(false)
        })
    }
    else {
      acquireToken(InteractionType.Popup).then((authresult) => {
        configurationService
        .CheckAppRoleAssignments(ProductConfiguration.EasyDirectory.ApplicationId, authresult.accessToken)
        .then((response) => {
          if (hasAllAppRoleAssignments(ProductConfiguration.EasyDirectory.ApplicationId, response)) {
            setConsentGranted(true)
          }
        })
        .catch((error) => {
          console.error('Error checking initial consent:', error)
          setConsentGranted(false)
        })
      });
    }
  }, [result, error, acquireToken])

  const handleConsentClick = () => {
    const consentUrl = `https://login.microsoftonline.com/common/adminconsent?client_id=${ProductConfiguration.EasyDirectory.ApplicationId}&redirect_uri=${REDIRECTURL}`
    window.open(consentUrl, 'consent', 'width=600,height=600')
  }

  const handleValidateClick = async () => {
    setValidationStatus('checking')
    setIsLoading(true)

    try {
      const auth = await acquireToken(InteractionType.Silent);

      if (auth) {
        const response = await configurationService.CheckAppRoleAssignments(ProductConfiguration.EasyDirectory.ApplicationId, auth.accessToken)
        const hasConsent = hasAllAppRoleAssignments(ProductConfiguration.EasyDirectory.ApplicationId, response)

        if (hasConsent) {
          setConsentGranted(true)
          setValidationStatus('success')
        } else {
          setConsentGranted(false)
          setValidationStatus('error')
        }
      } else {
        setConsentGranted(false)
        setValidationStatus('error')
      }
    } catch (error) {
      console.error('Error validating consent:', error)
      setValidationStatus('error')
      setConsentGranted(false)
    } finally {
      setIsLoading(false)
    }
  }

  const permissionsTable = [
    { permission: 'Contacts.Read', description: 'Read user contacts' },
    { permission: 'Contacts.Read.Shared', description: 'Read user and shared contacts' },
    { permission: 'MailboxSettings.Read', description: 'Read user mailbox settings' },
    { permission: 'Presence.Read.All', description: 'Read presence information of all users in your organization' },
    { permission: 'User.Read', description: 'Sign in and read user profile' },
    { permission: 'User.Read.All', description: 'Read all users\' full profiles' }
  ]

  return (
    <>
    <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
    <CCard>
      <CCardBody>
        <CCardTitle>Grant Azure AD Permissions</CCardTitle>

        <CCardText>
          To enable Easy Directory 2.0 functionality, your tenant administrator needs to grant the
          following permissions to the application:
        </CCardText>

        <CTable hover bordered className="mb-4">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell>Permission</CTableHeaderCell>
              <CTableHeaderCell>Description</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {permissionsTable.map(({ permission, description }) => (
              <CTableRow key={permission}>
                <CTableDataCell>{permission}</CTableDataCell>
                <CTableDataCell>{description}</CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>

        {consentGranted ? (
          <CAlert color="success" className="mb-4">
            All required permissions have been granted
          </CAlert>
        ) : (
          <div className="mb-4 d-flex gap-3">
            <CButton color="warning" onClick={handleConsentClick}>
              Grant Permissions
            </CButton>

            <CButton
              color={validationStatus === 'error' ? 'danger' : 'primary'}
              disabled={isLoading}
              onClick={handleValidateClick}
            >
              {isLoading ? (
                <>
                  <CSpinner size="sm" className="me-2" />
                  Validating...
                </>
              ) : validationStatus === 'error' ? (
                'Retry Validation'
              ) : (
                'Validate Permissions'
              )}
            </CButton>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <CButton color="light" onClick={onPrevious}>
            Previous
          </CButton>
          <CButton color="primary" onClick={onNext} disabled={!consentGranted}>
            Next
          </CButton>
        </div>
      </CCardBody>
    </CCard>
    </>
  )
}

export default ConsentStep

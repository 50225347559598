import { PublicClientApplication } from "@azure/msal-browser";
import { ProductConfiguration } from '../common/constants';
import EasyContextHandler from '../components/context/EasyContextHandler';
import { MsalProvider } from "@azure/msal-react";

const msalConfigurationCenterConfig = {
  auth: {
    clientId: ProductConfiguration.EasyCallReport.Admin.ApplicationId,
    redirectUri: ProductConfiguration.EasyCallReport.Admin.RedirectUrl,
  },
};

const configurationCenterPca = new PublicClientApplication(msalConfigurationCenterConfig);

const EasyCallReportAuthenticatedLayout = () => {
  return (
    <MsalProvider instance={configurationCenterPca}>
      <EasyContextHandler />
    </MsalProvider>
  )
}

export default EasyCallReportAuthenticatedLayout

import { useState, useEffect, useContext } from 'react';
import {
    CCard,
    CCardBody,
    CImage,
    CButton,
    CSpinner,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CLink,
    CAlert
} from '@coreui/react'
import {
    cilCheckAlt,
    cilExternalLink,
    cilLoopCircular
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { t } from 'i18next'

import EasyDirectoryLogo from '../../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'
import { Stepper } from 'react-form-stepper';

import './EasyDirectoryOnboarding.css';
import { useNavigate } from 'react-router-dom'
import { Login } from '@microsoft/mgt-react'
import { FluentProvider, Spinner, webLightTheme } from '@fluentui/react-components';
import { ProductConfiguration } from '../../../common/constants';
import { ConfigurationService } from '../../../services/ConfigurationService';
import { InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { EasyContext } from '../../../components/context/EasyContext';
import LoginButton from '../../../components/auth/LoginButton';

const EasyDirectory = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { accessToken } = useContext(EasyContext);
    const { accounts } = useMsal();
    const [isImporting, setIsImporting] = useState(false);

    const [activeKey, setActiveKey] = useState(sessionStorage.getItem("step") !== undefined ? +sessionStorage.getItem("step") : 0);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
    const retryDuration = 60000; // 1 minute in milliseconds
    const retryInterval = 1000; // Retry interval in milliseconds

    const configurationService = new ConfigurationService(accessToken);

    const steps = [
        { label: t('Onboarding.EasyDirectory.StepWelcome') },
        { label: t('Onboarding.EasyDirectory.Step1') },
        { label: t('Onboarding.EasyDirectory.Step2') },
        { label: t('Onboarding.EasyDirectory.IntermediateStep3') },
        { label: t('Onboarding.EasyDirectory.Step3') },
    ];

    const getSectionComponent = () => {
        switch (activeKey) {
            case 0: return <Welcome />;
            case 1: return <Step1 />;
            case 2: return <Step2 />;
            case 3: return <InstallationStep />;
            case 4: return <Step3 />;
            default: return null;
        }
    }

    const appRoleRequest = {
        scopes: ["Application.Read.All"],
        account: accounts[0]
    };

    const { acquireToken, result } = useMsalAuthentication(InteractionType.Silent, {
        ...appRoleRequest
    });

    const Welcome = () => {
        sessionStorage.setItem("step", activeKey.toString());
        setNextButtonEnabled(true);
        return (<div><h4>{t("Onboarding.EasyDirectory.WizardWelcomeTitle")}</h4>
            <br />
            {t("Onboarding.EasyDirectory.WizardWelcomeDescription")}
        </div>);
    }

    const Step1 = () => {
        const [loading, setLoading] = useState(false);
        const [consentGranted, setConsentGranted] = useState(false);
        const [btnColor, setBtnColor] = useState("primary");
        const [validationStatus, setValidationStatus] = useState<
            'idle' | 'checking' | 'success' | 'error'
        >('idle')

        const appId = ProductConfiguration.EasyDirectory.Admin.ApplicationId;

        const handleValidateClick = async () => {
            setValidationStatus('checking')
            setLoading(true)

            try {
                const auth = await acquireToken(InteractionType.Silent);

                if (auth) {
                    const response = await configurationService.CheckAppRoleAssignments(ProductConfiguration.EasyDirectory.ApplicationId, auth.accessToken)
                    const hasConsent = hasAllAppRoleAssignments(ProductConfiguration.EasyCallReport.Admin.ApplicationId, response.value)

                    if (hasConsent) {
                        setConsentGranted(true)
                        setValidationStatus('success')
                    } else {
                        setConsentGranted(false)
                        setValidationStatus('error')
                    }
                } else {
                    setConsentGranted(false)
                    setValidationStatus('error')
                }
            } catch (error) {
                console.error('Error validating consent:', error)
                setValidationStatus('error')
                setConsentGranted(false)
            } finally {
                setLoading(false)
            }
        }

        return (
            <div><h4>{t("Onboarding.EasyDirectory.WizardConfigurationConsentTitle")}</h4><br />
                {t("Onboarding.EasyDirectory.WizardConfigurationConsentDescription")}<br />
                <br />
                <CTable borderColor="light" hover bordered>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope="col">Permission</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        <CTableRow>
                            <CTableDataCell>Presence.Read.All</CTableDataCell>
                            <CTableDataCell>Read presence information of all users in your organization.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>User.Read</CTableDataCell>
                            <CTableDataCell>Sign in and read user profile.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>User.ReadBasic.All</CTableDataCell>
                            <CTableDataCell>Read all users' basic profiles</CTableDataCell>
                        </CTableRow>
                    </CTableBody>
                </CTable>
                {consentGranted ?
                    <CAlert color="success">
                        {t("PermissionGranted")}
                    </CAlert>
                    : <><CButton href={'https://login.microsoftonline.com/common/adminconsent?client_id=' + appId + '&redirect_uri=' + ProductConfiguration.EasyCallReport.Admin.RedirectUrl + 'popup-close.html'} color="warning" target="popup" onClick={() => {
                        window.open('https://login.microsoftonline.com/common/adminconsent?client_id=' + appId + "&redirect_uri=" + ProductConfiguration.EasyCallReport.Admin.RedirectUrl + "popup-close.html", 'popup', 'width=600,height=600');
                    }}><CIcon icon={cilExternalLink} />
                        &nbsp;&nbsp;{t("Onboarding.ConsentButton")}
                    </CButton>&nbsp;&nbsp;
                        <CButton
                            color={validationStatus === 'error' ? 'danger' : 'primary'}
                            disabled={loading}
                            onClick={handleValidateClick}
                        >
                            {loading ? (
                                <>
                                    <CSpinner size="sm" className="me-2" />
                                    Validating...
                                </>
                            ) : validationStatus === 'error' ? (
                                'Retry Validation'
                            ) : (
                                'Validate Permissions'
                            )}
                        </CButton>
                    </>
                }
            </div>)
    }

    const Step2 = () => {
        sessionStorage.setItem("step", activeKey.toString());
        const [loading, setLoading] = useState(false);
        const [consentGranted, setConsentGranted] = useState(false);
        const [btnColor, setBtnColor] = useState("primary");
        const [validationStatus, setValidationStatus] = useState<
            'idle' | 'checking' | 'success' | 'error'
        >('idle')

        // Set ECR Application Id
        const appId = ProductConfiguration.EasyDirectory.ApplicationId;

        const handleValidateClick = async () => {
            setValidationStatus('checking')
            setLoading(true)

            try {
                const auth = await acquireToken(InteractionType.Silent);

                if (auth) {
                    const response = await configurationService.CheckAppRoleAssignments(ProductConfiguration.EasyDirectory.ApplicationId, auth.accessToken)
                    const hasConsent = hasAllAppRoleAssignments(ProductConfiguration.EasyCallReport.Admin.ApplicationId, response.value)

                    if (hasConsent) {
                        setConsentGranted(true)
                        setValidationStatus('success')
                    } else {
                        setConsentGranted(false)
                        setValidationStatus('error')
                    }
                } else {
                    setConsentGranted(false)
                    setValidationStatus('error')
                }
            } catch (error) {
                console.error('Error validating consent:', error)
                setValidationStatus('error')
                setConsentGranted(false)
            } finally {
                setLoading(false)
            }
        }

        return (
            <div><h4>{t("Onboarding.EasyDirectory.WizardConsentTitle")}</h4><br />

                {t("Onboarding.EasyDirectory.WizardConsentDescription")}<br />
                <br />
                <CTable borderColor="light" hover bordered>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope="col">Permission</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        <CTableRow>
                            <CTableDataCell>Contacts.Read</CTableDataCell>
                            <CTableDataCell>Read contacts in all mailboxes</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>MailboxSettings.Read</CTableDataCell>
                            <CTableDataCell>Read all user mailbox settings</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>Presence.Read.All</CTableDataCell>
                            <CTableDataCell>Read presence information of all users in your organization</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>User.Read</CTableDataCell>
                            <CTableDataCell>Log in and read user profile</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>User.Read.All</CTableDataCell>
                            <CTableDataCell>Allows the app to read user profiles without a signed in user.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>User.Read.All</CTableDataCell>
                            <CTableDataCell>Read all users full profiles</CTableDataCell>
                        </CTableRow>
                    </CTableBody>
                </CTable>

                {consentGranted ?
                    <CAlert color="success">
                        {t("PermissionGranted")}
                    </CAlert>
                    : <><CButton href={'https://login.microsoftonline.com/common/adminconsent?client_id=' + appId + '&redirect_uri=' + ProductConfiguration.EasyDirectory.RedirectUrl + 'popup-close.html'} color="warning" target="popup" onClick={() => {
                        window.open('https://login.microsoftonline.com/common/adminconsent?client_id=' + appId + "&redirect_uri=" + ProductConfiguration.EasyDirectory.RedirectUrl + "popup-close.html", 'popup', 'width=600,height=600');
                    }}><CIcon icon={cilExternalLink} />
                        &nbsp;&nbsp;{t("Onboarding.ConsentButton")}
                    </CButton>&nbsp;&nbsp;
                        <CButton
                            color={validationStatus === 'error' ? 'danger' : 'primary'}
                            disabled={loading}
                            onClick={handleValidateClick}
                        >
                            {loading ? (
                                <>
                                    <CSpinner size="sm" className="me-2" />
                                    Validating...
                                </>
                            ) : validationStatus === 'error' ? (
                                'Retry Validation'
                            ) : (
                                'Validate Permissions'
                            )}
                        </CButton>
                    </>
                }
            </div>);
    }

    const [installationStep, setInstallationStep] = useState("");


    const provisioningElasticSearch = async () => {
        try {
            setInstallationStep(t("Onboarding.EasyDirectory.Provisioning"));
            const response = await configurationService.provisioningElasticSearch();
            if (response.status === 502) {
                return false;
            } else {
                setInstallationStep("");
                return true;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const InstallationStep = () => {
        if (!isImporting) {
            setIsImporting(true);
            try {
                const installationCompleted = provisioningElasticSearch().then(async (res) => {
                    if (res === false) {
                        setInstallationStep(t("Onboarding.EasyDirectory.AlreadyInitialized"));
                        setTimeout(() => {
                            setIsImporting(false);
                            setActiveKey(+activeKey + 1);
                        }, 5000);
                        return res;
                    } else {
                        setIsImporting(false);
                        setActiveKey(+activeKey + 1);
                        return res;
                    }
                }).catch(() => {
                    setIsImporting(false);
                    setActiveKey(+activeKey + 1);
                    return true;
                });
                if (!installationCompleted) {
                    setInstallationStep(t("Onboarding.ErrorInstalling"));
                }
            } catch (e) {
                setInstallationStep(t("Onboarding.ErrorInstalling"));
            }
            sessionStorage.setItem("step", activeKey.toString());
        }
        return (
            <div>
                <h4>{t('Onboarding.EasyDirectory.IntermediateStep3')}</h4><br />
                {t("Onboarding.EasyDirectory.WizardConnectDescription")}
                <br /><br />
                <FluentProvider theme={webLightTheme}>
                    <div style={{ padding: "20px" }}>
                        <Spinner labelPosition="below" label={installationStep !== "" && installationStep} />
                    </div>
                </FluentProvider>
            </div>
        );
    }

    const Step3 = () => {
        return (
            <div><h4>{t("Onboarding.EasyDirectory.WizardConfigureTitle")}</h4><br />
                {t("Onboarding.EasyDirectory.WizardConfigureDescription")}
                <br /><br />
                <CLink href="https://docs.easydirectory.app/" target="_blank">Easy Directory Guide</CLink>
                <br /><br />
                <CButton color="light" className="tcf-button-next" onClick={() => window.open('msteams://teams.cloud.microsoft.com/')}><CIcon icon={cilExternalLink} />&nbsp;&nbsp;{t("Onboarding.OpenTeamsApp")}</CButton>
            </div>);
    }

    const checkAppRoleAssignments = async (appId: string, token: string) => {
        try {
            const response = await configurationService.CheckAppRoleAssignments(appId, token);
            if (hasAllAppRoleAssignments(appId, response.value)) {
                setNextButtonEnabled(true);
                return true;
            }
        } catch (error) {
            setNextButtonEnabled(false);
            return false;
        }

        return false;
    };

    const hasAllAppRoleAssignments = (appId, roles) => {
        if (ProductConfiguration.EasyDirectory.ApplicationId === appId) {
            return true;
            // return roles.some(item => item.appRoleId === "089fe4d0-434a-44c5-8827-41ba8a0b17f5")
            //     && roles.some(item => item.appRoleId === "40f97065-369a-49f4-947c-6a255697ae91")
            //     && roles.some(item => item.appRoleId === "df021288-bdef-4463-88db-98f22de89214");
        }
        return false;
    }

    return (
        <div>
            <br />
            <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
            <Stepper
                steps={steps}
                activeStep={activeKey} />
            <CCard>
                <CCardBody>
                    <div style={{ padding: '20px' }}>
                        {getSectionComponent()}
                        <hr className='tcf-divider' />
                        {(activeKey !== 0 && activeKey !== 3)
                            && <CButton color="light" className="tcf-button-prev" onClick={() => setActiveKey(+activeKey - (activeKey === steps.length - 1 ? 2 : 1))}>{t("Onboarding.Prev")}</CButton>
                        }
                        {activeKey === 0 && isAuthenticated === false
                            && <LoginButton />
                        }
                        {activeKey !== steps.length - 1 && isAuthenticated === true && activeKey !== 3
                            && <CButton color="secondary" className="tcf-button-next" onClick={() => {
                                setActiveKey(+activeKey + 1);
                                setNextButtonEnabled(false);
                            }} disabled={!nextButtonEnabled}>{t("Onboarding.Next")}</CButton>
                        }
                        {activeKey === 4
                            && <CButton color="success" className="tcf-button-next" onClick={() => navigate('/easydirectory')}><CIcon icon={cilCheckAlt} />&nbsp;&nbsp;{t("Onboarding.CloseWizard")}</CButton>
                        }
                    </div>
                </CCardBody>
            </CCard>
            <br /><br /><br />
        </div>
    )
}

export default EasyDirectory
import { useState, useEffect, useCallback, useContext } from 'react';
import {
    CCard,
    CCardBody,
    CImage,
    CButton,
    CSpinner,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CLink,
    CAlert,
} from '@coreui/react'
import { cilCheckAlt, cilExternalLink, cilInputPower, cilLoopCircular } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { t } from 'i18next'

import EasyCallReportLogo from '../../../assets/brand/TCF_EasyCallReport_Logo_RGB.png'
import { Stepper } from 'react-form-stepper';

import './EasyCallReportOnboarding.css';
import { useNavigate } from 'react-router-dom'
import { Login } from '@microsoft/mgt-react'
import { ProductConfiguration } from '../../../common/constants';
import { ConfigurationService } from '../../../services/ConfigurationService';
import { EasyContext } from '../../../components/context/EasyContext';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import LoginButton from '../../../components/auth/LoginButton';
import { InteractionType } from '@azure/msal-browser';

const EasyCallReportOnboarding = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { accessToken } = useContext(EasyContext);
    const { accounts } = useMsal();

    const [activeKey, setActiveKey] = useState(sessionStorage.getItem("ECRStep") !== undefined ? +sessionStorage.getItem("ECRStep") : 0);
    const [isRenewSubscriptionLoading, setIsRenewSubscriptionLoading] = useState(false);
    const [isRenewSubscriptionSuccessful, setIsRenewSubscriptionSuccessful] = useState(false);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
    const [renewSubscriptionBtnColor, setRenewSubscriptionBtnColor] = useState("warning");

    const retryDuration = 60000; // 1 minute in milliseconds
    const retryInterval = 1000; // Retry interval in milliseconds

    const configurationService = new ConfigurationService(accessToken);

    const appRoleRequest = {
        scopes: ["Application.Read.All"],
        account: accounts[0]
    };

    const { acquireToken, result, error } = useMsalAuthentication(InteractionType.Silent, {
        ...appRoleRequest
    });

    const steps = [
        { label: t('Onboarding.EasyCallReport.StepWelcome') },
        { label: t('Onboarding.EasyCallReport.Step1') },
        { label: t('Onboarding.EasyCallReport.Step2') },
        { label: t('Onboarding.EasyCallReport.Step3') },
        { label: t('Onboarding.EasyCallReport.Step4') },
    ];

    const getSectionComponent = () => {
        switch (activeKey) {
            case 0: return <Welcome />;
            case 1: return <Step1 />;
            case 2: return <Step2 />;
            case 3: return <Step3 />;
            case 4: return <Step4 />;
            default: return null;
        }
    }

    const Welcome = () => {
        sessionStorage.setItem("ECRStep", activeKey.toString());
        setNextButtonEnabled(true);
        return (<div><h4>{t("Onboarding.EasyCallReport.WizardWelcomeTitle")}</h4>
            <br />
            {t("Onboarding.EasyCallReport.WizardWelcomeDescription")}
        </div>);
    }

    const Step1 = () => {
        sessionStorage.setItem("ECRStep", activeKey.toString());
        const [loading, setLoading] = useState(false);
        const [consentGranted, setConsentGranted] = useState(false);
        const [btnColor, setBtnColor] = useState("primary");
        const [validationStatus, setValidationStatus] = useState<
            'idle' | 'checking' | 'success' | 'error'
        >('idle')

        const appId = ProductConfiguration.EasyCallReport.Admin.ApplicationId;

        const handleValidateClick = async () => {
            setValidationStatus('checking')
            setLoading(true)

            try {
                const auth = await acquireToken(InteractionType.Silent);

                if (auth) {
                    const response = await configurationService.CheckAppRoleAssignments(ProductConfiguration.EasyCallReport.ApplicationId, auth.accessToken)
                    const hasConsent = hasAllAppRoleAssignments(ProductConfiguration.EasyCallReport.Admin.ApplicationId, response.value)

                    if (hasConsent) {
                        setConsentGranted(true)
                        setValidationStatus('success')
                        setNextButtonEnabled(true)
                    } else {
                        setConsentGranted(false)
                        setValidationStatus('error')
                        setNextButtonEnabled(false)
                    }
                } else {
                    setConsentGranted(false)
                    setValidationStatus('error')
                }
            } catch (error) {
                console.error('Error validating consent:', error)
                setValidationStatus('error')
                setConsentGranted(false)
            } finally {
                setLoading(false)
            }
        }

        return (
            <div><h4>{t("Onboarding.EasyCallReport.WizardConfigurationConsentTitle")}</h4><br />
                {t("Onboarding.EasyCallReport.WizardConfigurationConsentDescription")}<br />
                <br />
                <CTable borderColor="light" hover bordered>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope="col">Permission</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        <CTableRow>
                            <CTableDataCell>Channel.ReadBasic.All</CTableDataCell>
                            <CTableDataCell>Read all channel names and channel descriptions, without a signed-in user.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>Team.ReadBasic.All</CTableDataCell>
                            <CTableDataCell>Read the names and descriptions of teams, on behalf of the signed-in user.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>Team.ReadBasic.All (Application)</CTableDataCell>
                            <CTableDataCell>Get a list of all teams, without a signed-in user.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>TeamsAppInstallation.ReadForTeam.All</CTableDataCell>
                            <CTableDataCell>Allows the app to read the Teams apps that are installed in any team, without a signed-in user. Does not give the ability to read application-specific settings.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>TeamsAppInstallation.ReadWriteAndConsentForTeam.All</CTableDataCell>
                            <CTableDataCell>Allows the app to read, install, upgrade, and uninstall Teams apps in any team, without a signed-in user. Gives the ability to manage permission grants for accessing those specific teams&apos; data.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>TeamSettings.ReadWrite.All</CTableDataCell>
                            <CTableDataCell>Read and change all teams&apos; settings, without a signed-in user.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>Skype and Teams user_impersonation</CTableDataCell>
                            <CTableDataCell>Access Microsoft Teams and Skype for Business data based on the user&apos;s role membership</CTableDataCell>
                        </CTableRow>
                    </CTableBody>
                </CTable>
                {consentGranted ?
                    <CAlert color="success">
                        {t("PermissionGranted")}
                    </CAlert>
                    : <><CButton href={'https://login.microsoftonline.com/common/adminconsent?client_id=' + appId + '&redirect_uri=' + ProductConfiguration.EasyCallReport.Admin.RedirectUrl + 'popup-close.html'} color="warning" target="popup" onClick={() => {
                        window.open('https://login.microsoftonline.com/common/adminconsent?client_id=' + appId + "&redirect_uri=" + ProductConfiguration.EasyCallReport.Admin.RedirectUrl + "popup-close.html", 'popup', 'width=600,height=600');
                    }}><CIcon icon={cilExternalLink} />
                        &nbsp;&nbsp;{t("Onboarding.ConsentButton")}
                    </CButton>&nbsp;&nbsp;
                        <CButton
                            color={validationStatus === 'error' ? 'danger' : 'primary'}
                            disabled={loading}
                            onClick={handleValidateClick}
                        >
                            {loading ? (
                                <>
                                    <CSpinner size="sm" className="me-2" />
                                    Validating...
                                </>
                            ) : validationStatus === 'error' ? (
                                'Retry Validation'
                            ) : (
                                'Validate Permissions'
                            )}
                        </CButton>
                    </>
                }
            </div>)
    }

    const Step2 = () => {
        sessionStorage.setItem("ECRStep", activeKey.toString());
        const [loading, setLoading] = useState(false);
        const [consentGranted, setConsentGranted] = useState(false);
        const [btnColor, setBtnColor] = useState("primary");
        const [validationStatus, setValidationStatus] = useState<
            'idle' | 'checking' | 'success' | 'error'
        >('idle')

        // Set ECR Application Id
        const appId = ProductConfiguration.EasyCallReport.ApplicationId;

        const handleValidateClick = async () => {
            setValidationStatus('checking')
            setLoading(true)

            try {
                const auth = await acquireToken(InteractionType.Silent);

                if (auth) {
                    const response = await configurationService.CheckAppRoleAssignments(ProductConfiguration.EasyCallReport.ApplicationId, auth.accessToken)
                    const hasConsent = hasAllAppRoleAssignments(ProductConfiguration.EasyCallReport.Admin.ApplicationId, response.value)

                    if (hasConsent) {
                        setConsentGranted(true)
                        setValidationStatus('success')
                        setNextButtonEnabled(true)
                    } else {
                        setConsentGranted(false)
                        setValidationStatus('error')
                        setNextButtonEnabled(false)
                    }
                } else {
                    setConsentGranted(false)
                    setValidationStatus('error')
                }
            } catch (error) {
                console.error('Error validating consent:', error)
                setValidationStatus('error')
                setConsentGranted(false)
            } finally {
                setLoading(false)
            }
        }

        return (
            <div><h4>{t("Onboarding.EasyCallReport.WizardEasyCallReportConsentTitle")}</h4><br />

                {t("Onboarding.EasyCallReport.WizardEasyCallReportConsentDescription")}<br />
                <br />
                <CTable borderColor="light" hover bordered>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope="col">Permission</CTableHeaderCell>
                            <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        <CTableRow>
                            <CTableDataCell>CallRecord-PstnCalls.Read.All</CTableDataCell>
                            <CTableDataCell>Allows the app to read all PSTN and direct routing call log data without a signed-in user.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>CallRecords.Read.All</CTableDataCell>
                            <CTableDataCell>Allows the app to read call records for all calls and online meetings without a signed-in user.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>Presence.Read.All</CTableDataCell>
                            <CTableDataCell>Allows the app to read presence information of all users in the directory on behalf of the signed-in user. Presence information includes activity, availability, status note, calendar out-of-office message, timezone and location.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>User.Read</CTableDataCell>
                            <CTableDataCell>Allows users to sign-in to the app, and allows the app to read the profile of signed-in users. It also allows the app to read basic company information of signed-in users.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>User.Read.All</CTableDataCell>
                            <CTableDataCell>Allows the app to read user profiles without a signed in user.</CTableDataCell>
                        </CTableRow>
                        <CTableRow>
                            <CTableDataCell>User.ReadBasic.All</CTableDataCell>
                            <CTableDataCell>Allows the app to read a basic set of profile properties of other users in your organization on behalf of the signed-in user. This includes display name, first and last name, email address and photo.</CTableDataCell>
                        </CTableRow>
                    </CTableBody>
                </CTable>

                {consentGranted ?
                    <CAlert color="success">
                        {t("PermissionGranted")}
                    </CAlert>
                    : <><CButton href={'https://login.microsoftonline.com/common/adminconsent?client_id=' + appId + '&redirect_uri=' + ProductConfiguration.EasyCallReport.RedirectUrl + 'popup-close.html'} color="warning" target="popup" onClick={() => {
                        window.open('https://login.microsoftonline.com/common/adminconsent?client_id=' + appId + "&redirect_uri=" + ProductConfiguration.EasyCallReport.RedirectUrl + "popup-close.html", 'popup', 'width=600,height=600');
                    }}><CIcon icon={cilExternalLink} />
                        &nbsp;&nbsp;{t("Onboarding.ConsentButton")}
                    </CButton>&nbsp;&nbsp;
                        <CButton
                            color={validationStatus === 'error' ? 'danger' : 'primary'}
                            disabled={loading}
                            onClick={handleValidateClick}
                        >
                            {loading ? (
                                <>
                                    <CSpinner size="sm" className="me-2" />
                                    Validating...
                                </>
                            ) : validationStatus === 'error' ? (
                                'Retry Validation'
                            ) : (
                                'Validate Permissions'
                            )}
                        </CButton>
                    </>
                }
            </div>);
    }

    const Step3 = () => {
        sessionStorage.setItem("ECRStep", activeKey.toString());
        return (isAuthenticated ?
            <div><h4>{t("Onboarding.EasyCallReport.WizardConnectTitle")}</h4><br />
                {t("Onboarding.EasyCallReport.WizardConnectDescription")}<br />
                <br />
                <CButton color={renewSubscriptionBtnColor} disabled={isRenewSubscriptionSuccessful} onClick={() => {
                    setIsRenewSubscriptionLoading(true);
                    new ConfigurationService(accessToken).RenewSubscription()
                        .then(() => {
                            setIsRenewSubscriptionLoading(false);
                            setIsRenewSubscriptionSuccessful(true);
                            setRenewSubscriptionBtnColor("success");
                            setNextButtonEnabled(true);
                        })
                        .catch(() => {
                            setIsRenewSubscriptionLoading(false);
                            setRenewSubscriptionBtnColor("danger");
                            alert(t("ErrorTenantConnection"));
                            setNextButtonEnabled(false);
                        });
                }}>
                    {isRenewSubscriptionLoading === true
                        && <CSpinner component="span" size="sm" aria-hidden="true" />}
                    {isRenewSubscriptionSuccessful === true
                        && <CIcon icon={cilCheckAlt} />}
                    {isRenewSubscriptionLoading === false && isRenewSubscriptionSuccessful === false
                        && <CIcon icon={cilInputPower} />}
                    &nbsp;&nbsp;{t("Onboarding.ConnectButton")}
                </CButton>
            </div> : <div><h4>{t("Onboarding.EasyCallReport.WizardConnectTitle")}</h4><br />

                {t("Onboarding.EasyCallReport.WizardConnectDescription")}<br />
                <br />
                <Login /></div>);
    }

    const Step4 = () => {
        sessionStorage.setItem("ECRStep", activeKey.toString());
        return (
            <div><h4>{t("Onboarding.EasyCallReport.WizardConfigureTitle")}</h4><br />

                {t("Onboarding.EasyCallReport.WizardConfigureDescription")}<br />
                <br />
                <CLink href="https://docs.easycallreport.easyplatform.app/getting-started/easy-call-report-configuration" target="_blank">Easy Call Report Configuration Guide</CLink>
            </div>);
    }

    const hasAllAppRoleAssignments = (appId, roles) => {
        if (ProductConfiguration.EasyCallReport.Admin.ApplicationId === appId) {
            return roles.some(item => item.appRoleId === "f6b49018-60ab-4f81-83bd-22caeabfed2d")
                && roles.some(item => item.appRoleId === "b8bb2037-6e08-44ac-a4ea-4674e010e2a4")
                && roles.some(item => item.appRoleId === "4c277553-8a09-487b-8023-29ee378d8324")
                && roles.some(item => item.appRoleId === "5b567255-7703-4780-807c-7be8301ae99b")
                && roles.some(item => item.appRoleId === "fd7ccf6b-3d28-418b-9701-cd10f5cd2fd4")
                && roles.some(item => item.appRoleId === "45bbb07e-7321-4fd7-a8f6-3ff27e6a81c8")
                && roles.some(item => item.appRoleId === "a2611786-80b3-417e-adaa-707d4261a5f0")
                && roles.some(item => item.appRoleId === "df021288-bdef-4463-88db-98f22de89214")
                && roles.some(item => item.appRoleId === "a7a681dc-756e-4909-b988-f160edc6655f")
                && roles.some(item => item.appRoleId === "284383ee-7f6e-4e40-a2a8-e85dcb029101");
        } else if (ProductConfiguration.EasyCallReport.ApplicationId === appId) {
            return roles.some(item => item.appRoleId === "45bbb07e-7321-4fd7-a8f6-3ff27e6a81c8")
                && roles.some(item => item.appRoleId === "a2611786-80b3-417e-adaa-707d4261a5f0")
                && roles.some(item => item.appRoleId === "df021288-bdef-4463-88db-98f22de89214");
        }
    }
    return (
        <div>
            <br />
            <CImage fluid src={EasyCallReportLogo} alt="Easy Call Report" width={370} /><br /><br />

            <Stepper
                steps={steps}
                activeStep={activeKey} />
            <CCard>
                <CCardBody>
                    <div style={{ padding: '20px' }}>
                        {getSectionComponent()}
                        <hr className='tcf-divider' />
                        {(activeKey !== 0 && activeKey !== steps.length - 1)
                            && <CButton color="light" className="tcf-button-prev" onClick={() => setActiveKey(activeKey - 1)}>{t("Onboarding.Prev")}</CButton>
                        }
                        {activeKey === 0 && isAuthenticated === false
                            && <LoginButton />
                        }
                        {activeKey !== steps.length - 1 && isAuthenticated === true
                            && <CButton color="secondary" className="tcf-button-next" onClick={() => {
                                setActiveKey(activeKey + 1);
                                setNextButtonEnabled(false);
                            }} disabled={!nextButtonEnabled}>{t("Onboarding.Next")}</CButton>
                        }
                        {activeKey === 4
                            && <CButton color="success" className="tcf-button-next" onClick={() => navigate('/easycallreport')}><CIcon icon={cilCheckAlt} />&nbsp;&nbsp;{t("Onboarding.CloseWizard")}</CButton>
                        }
                    </div>
                </CCardBody>
            </CCard>
            <br /><br /><br />
        </div>
    )
}

export default EasyCallReportOnboarding

import { useEffect, useState, useRef, useCallback, useContext, useMemo } from 'react'
import Select from 'react-select'
import ReactPlayer from 'react-player'

import EasyCallReportLogo from '../../assets/brand/TCF_EasyCallReport_Logo_RGB.png'
import LicenseImg from '../../assets/images/Step1_BuyLicense.jpg'
import SupportImg from '../../assets/images/Step4_Support.jpg'

import { BiSolidPhoneCall } from "react-icons/bi";
import { FaRobot } from "react-icons/fa6";
import { v4 as uuid } from 'uuid';

import {
  CRow,
  CCol,
  CCard,
  CCardBody,
  CButton,
  CCardTitle,
  CCardText,
  CCardImage,
  CFormSwitch,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CSpinner,
  CImage,
  CTooltip,
  CLink,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CWidgetStatsB,
  CWidgetStatsA,
  CToast,
  CToastBody,
  CToastClose,
  CToaster
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import {
  cilPhone,
  cilLoopCircular,
  cilSpeedometer,
  cilX,
} from '@coreui/icons';

import { useTranslation } from "react-i18next";

import "./EasyCallReport.css";
import { CChartLine } from '@coreui/react-chartjs';
import { ProductConfiguration } from '../../common/constants'
import { EasyCallReportConfiguration, EasyTag, TeamsAssignment } from '../../common/interfaces'
import EasyPlannerPicker from '../../components/EasyPlannerPicker'
import {EasyTeamsChannelPicker} from '../../components/EasyTeamsChannelPicker'
import { ConfigurationService } from '../../services/ConfigurationService'
import { GraphService } from '../../services/GraphService'
import { TelemetryService } from '../../services/TelemetryService'
import timezones from '../../assets/timezones.json'
import { EasyContext } from '../../components/context/EasyContext'
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

const EasyCallReport = () => {
  const { t } = useTranslation();
  const funMessage = t("Loading");

  const { accounts } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const easyContext = useContext(EasyContext);

  const configurationService = new ConfigurationService(easyContext.accessToken);
  const telemetryService = new TelemetryService(easyContext.accessToken);

  const [configuration, setConfiguration] = useState<EasyCallReportConfiguration>();
  const [isPageConfigLoading, setIsPageConfigLoading] = useState(false);
  // const [isRenewSubscriptionLoading, setIsRenewSubscriptionLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(t("SettingsSaved"));
  const [loadingMsg, setLoadingMsg] = useState(funMessage);
  const [saveCounter, setSaveCounter] = useState(0);
  const [callQueues, setCallQueues] = useState([]);
  const [autoAttendants, setAutoAttendants] = useState([]);
  const [groupedVoiceApps, setGroupedVoiceApps] = useState([]);
  const [voiceAppCount, setVoiceAppCount] = useState(0);
  const [oldEcrModalVisible, setOldEcrModalVisible] = useState(false);

  const [IsLicenseLimitReached, setIsLicenseLimitReached] = useState(false);

  const [callsLastMonth, setCallsLastMonth] = useState(0);
  const [missedCallsPercent, setMissedCallsPercent] = useState(0);
  const [answeredCallsPercent, setAnsweredCallsPercent] = useState(0);
  const [highestCQMissedCount, setHighestCQMissedCount] = useState(0);
  const [highestCQMissedPercent, setHighestCQMissedPercent] = useState(0);
  const [highestCQName, setHighestCQName] = useState("");
  const [callsGroupedByCW, setCallsGroupedByCW] = useState("");

  const [toast, addToast] = useState<any>();
  const toaster = useRef();
  
  const [graphService, setGraphService] = useState(() => new GraphService(easyContext.accessToken, configuration?.enablePlanner));

  const getCallsOfLast30Days = useCallback(() => {
    telemetryService.getCallsOfLast30Days().then(calls => {
      setCallsLastMonth(calls.length);

      const missedCalls = calls.filter(function (mC) {
        return mC.Type === 2;
      });

      for (const c in calls) {
        calls[c].StartDateTime = getDateWeek(new Date(calls[c].StartDateTime)).toString();
      }

      const groupedByCQ = groupBy(missedCalls, "CallQueue");
      const groupedByCW = groupBy(calls, "StartDateTime");

      setCallsGroupedByCW(groupedByCW);

      let highestCQLength = 0;

      Object.entries(groupedByCQ).forEach(item => {
        const groupedCalls = (item[1] as Array<any>);

        if (groupedCalls.length > highestCQLength) {
          const missedCalls = groupedCalls.filter(function (mC) {
            return mC.Type === 2;
          });


          setHighestCQMissedPercent(Math.round(missedCalls.length / groupedCalls.length * 100));

          setHighestCQName(item[0]);
          setHighestCQMissedCount(missedCalls.length);
          highestCQLength = missedCalls.length;
        }
      });

      const answeredCalls = calls.filter(function (aC) {
        return aC.Type === 1;
      });

      setMissedCallsPercent(missedCalls.length / calls.length * 100);
      setAnsweredCallsPercent(answeredCalls.length / calls.length * 100);
    });
  }, []);

  const impersonateRequest = {
    scopes: [ProductConfiguration.EasyCallReport.Admin.ElevatedScope],
    account: accounts[0]
  };

  const { acquireToken } = useMsalAuthentication(InteractionType.Silent, {
    ...impersonateRequest
  });

  // Load Call Queues and Auto Attendants on Page Load
  const getCallQueues = useCallback(() => {
    setIsPageConfigLoading(true);
    setLoadingMsg(t("Loading"));

    acquireToken(InteractionType.Silent).then(result => {
      configurationService.getCallQueues(result.accessToken).then((result => {
        setCallQueues(
          result.map((callqueue) => {
            return {
              value: callqueue.applicationInstances[0],
              label: callqueue.name,
              mode: "CallQueue"
            }
          }),
        );
      }));

      configurationService.getAutoAttendants(result.accessToken).then((result => {
        setAutoAttendants(
          result.map((autoattendant) => {
            return {
              value: autoattendant.applicationInstances[0],
              label: autoattendant.name,
              mode: "AutoAttendant"
            }
          }),
        );
        setIsPageConfigLoading(false);
      }));
    });
    

    
  }, []);

  const getConfiguration = useCallback(() => {
    setIsPageConfigLoading(true);

    configurationService.GetConfiguration().then((config) => {
      setConfiguration(config);

      // if (config.version === undefined || config.version === "1.0" || config.version === "1.1" || config.version === "2.0") {
      //   setOldEcrModalVisible(true);
      // }

      RenderCallQueueItems();

      const count = config?.teamsAssignments?.length;

      if (count !== undefined && count !== null) {
        setVoiceAppCount(count);
      }
      setIsPageConfigLoading(false)
    }).catch((e) => {
      setErrorMsg(e.message.message);
      addToast(errorToast);
      setIsPageConfigLoading(false);
    });

  }, []);

  useMemo(() => {
    setGraphService(new GraphService(easyContext.accessToken, configuration?.enablePlanner));
  }, [configuration]);

  // On Page Load
  useEffect(() => {
    setIsPageConfigLoading(true);

    getCallQueues();
    getCallsOfLast30Days();
    getConfiguration();

  }, []);


  // Process and update configuration
  useEffect(() => {
    if (isEmpty(configuration) === true)
      return;

    if (saveCounter === 0) // On page load this event is fired during writing the configuration => we can skip that for saving
    {
      setSaveCounter(saveCounter + 1)
      return;
    }

    const updateConfig = async () => await configurationService.UpdateFullConfiguration(configuration).then(() => {
      setSuccessMsg(t("SettingsSaved"));
      addToast(successfulToast);
    }).catch((e) => {
      setErrorMsg(e.message.message);
      addToast(errorToast);
    });

    updateConfig();
    RenderCallQueueItems();

    const count = configuration?.teamsAssignments?.length;

    if (count !== undefined && count !== null) {
      setVoiceAppCount(count);
    }
  }, [configuration]);


  // Update License Counter
  useEffect(() => {
    const licenseDiff = easyContext.productLicenses?.EasyCallReport?.seatCount - voiceAppCount;
    
    if (licenseDiff > 0) {
      setIsLicenseLimitReached(false);
    } else {
      setIsLicenseLimitReached(true);
    }
  }, [voiceAppCount, easyContext.productLicenses]);


  // Render call queues and auto attendants dropdown
  useEffect(() => {
    setGroupedVoiceApps([
      {
        label: t("AutoAttendants"),
        options: autoAttendants
      },
      {
        label: t("CallQueues"),
        options: callQueues
      }
    ]);
  }, [callQueues, autoAttendants]);

  const getDateWeek = (date) => {
    const currentDate =
      (typeof date === 'object') ? date : new Date();
    const januaryFirst =
      new Date(currentDate.getFullYear(), 0, 1);
    const daysToNextMonday =
      (januaryFirst.getDay() === 1) ? 0 :
        (7 - januaryFirst.getDay()) % 7;
    const nextMonday =
      new Date(currentDate.getFullYear(), 0,
        januaryFirst.getDate() + daysToNextMonday);

    return (currentDate < nextMonday) ? 52 :
      (currentDate > nextMonday ? Math.ceil(
        (currentDate - (nextMonday as any)) / (24 * 3600 * 1000) / 7) : 1);
  }

  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const isEmpty = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  const successfulToast = (
    <CToast autohide={true} visible={true} color="success" role="img" className="text-white align-items-center" onClose={() => { setSuccessMsg("") }}>
      <div className="d-flex">
        <CToastBody>{successMsg}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )

  const errorToast = (
    <CToast autohide={true} visible={true} color="danger" role="img" className="text-white align-items-center" onClose={() => { setErrorMsg("") }}>
      <div className="d-flex">
        <CToastBody>{errorMsg}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )

  function updateConfiguration(updates: Partial<EasyCallReportConfiguration>): void {
    setConfiguration({ ...configuration, ...updates });
  }

  const RemoveCallQueue = (voiceAppId: string) => {
    const config: EasyCallReportConfiguration = configuration;
    const TeamsAssignmentConfig: Partial<EasyCallReportConfiguration> = {
      teamsAssignments: config?.teamsAssignments.filter(voiceApp => voiceApp.voiceAppId !== voiceAppId)
    }

    updateConfiguration(TeamsAssignmentConfig);
  }

  const handleAvailableTag = (voiceAppId: string, tag: EasyTag, mode: string) => {
    const config: EasyCallReportConfiguration = configuration;

    const TeamsAssignmentConfig: Partial<EasyCallReportConfiguration> = {
      teamsAssignments: config?.teamsAssignments
    }

    TeamsAssignmentConfig.teamsAssignments?.forEach(TeamsAssignment => {
      if (TeamsAssignment.voiceAppId === voiceAppId) {

        if (mode === "add") {
          TeamsAssignment?.availableTags?.push(tag);
        } else if (mode === "delete") {
          TeamsAssignment.availableTags = TeamsAssignment?.availableTags?.filter(removaltag => removaltag.id !== tag.id)
        }
      }
    });
    updateConfiguration(TeamsAssignmentConfig);
  }

  const updateCallQueueConfiguration = (voiceApp: TeamsAssignment) => {
    const config: EasyCallReportConfiguration = configuration;
    let existingVoiceApp = false;

    const TeamsAssignmentConfig: Partial<EasyCallReportConfiguration> = {
      teamsAssignments: config?.teamsAssignments
    }

    TeamsAssignmentConfig.teamsAssignments?.forEach(t => {
      if (t.voiceAppId === voiceApp.voiceAppId) {
        existingVoiceApp = true;

        Object.entries(voiceApp).forEach(([key, value]) => {
          t[key] = value;
        });
      }
    });

    if (existingVoiceApp === false) {
      TeamsAssignmentConfig.teamsAssignments?.push(voiceApp);
    }

    try {
      if (voiceApp.teamsId !== null && voiceApp.channelId !== null)
        configurationService.UpdateChannelAssociation(voiceApp);
    } catch (e) {
      console.log(e);
    }
    try {
      if (voiceApp.channelId !== null) {
        configurationService.SendConfigurationChangeNotification({ tenantId: configuration.tenantId, channelId: voiceApp.channelId });
      }
    } catch (e) {
      console.log(e);
    }

    // if (voiceApp.channelId !== undefined && voiceApp.channelId !== null) {
    //   try {
    //     configurationService.SendConfigurationChangeNotification({ tenantId: configuration.tenantId, channelId: voiceApp.channelId });   
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }


    updateConfiguration(TeamsAssignmentConfig);

    if (voiceApp.channelId !== null) {
      if (window.location.hostname !== "localhost") {
        configurationService.SendConfigurationChangeNotification({ tenantId: configuration.tenantId, channelId: voiceApp.channelId });
      }
    }
  }

  const onOptionSelect = (item, event, data) => {

    const nodeName = event.target.nodeName.toLowerCase();
    if (nodeName === "svg") {
      updateCallQueueConfiguration({ voiceAppId: item.voiceAppId, teamsId: null, channelId: null });
    } else {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(input => setTimeout(() => (input as HTMLInputElement).blur(), 0));
      if (data.optionText !== undefined && data.optionValue !== undefined) {
        const channel = JSON.parse(data?.optionValue).channel;
        graphService.getGoupsByName(data?.optionText?.split(" > ")[0].toLowerCase()).then(
          team => {
            const selectedTeam = team[0];
            graphService.getChannelsByGroup(selectedTeam.id).then(channels => {
              const selectedChannel = channels?.filter(
                (option) => option.id.indexOf(channel.id) === 0
              )[0];
              updateCallQueueConfiguration({ voiceAppId: item.voiceAppId, teamsId: selectedTeam?.id, channelId: selectedChannel?.id });
              event.preventDefault();
            }
            );
          });
      }
    }
  };

  const OnSelectedCallQueue = (
    newValue,
    actionMeta
  ) => {
    switch (actionMeta.action) {
      case 'select-option':
        updateCallQueueConfiguration({ voiceAppId: newValue.value, voiceAppName: newValue.label, voiceAppType: newValue.mode, showAnsweredCalls: false, showNotificationInChannel: true, language: "en-us", timeZone: "W. Europe Standard Time" });
        break;
      case 'remove-value':
      case 'pop-value':
        break;
    }
  };

  const GetVoiceAppType = (item: any) => {
    switch (item) {
      case 1:
      case "AutoAttendant":
        return <CTooltip content={t("AutoAttendant")}><CLink><FaRobot /></CLink></CTooltip>;
      case 0:
      case "CallQueue":
        return <CTooltip content={t("CallQueue")}><CLink><BiSolidPhoneCall /></CLink></CTooltip>;
      case 2:
      case "ResourceAccount":
        return <CTooltip content={t("ResourceAccount")}><CLink><CIcon icon={cilSpeedometer} /></CLink></CTooltip>;
      default:
        return <></>
    }
  }

  const GetFlags = (voiceAppId, selectedLanguage) => {
    const language = selectedLanguage?.toLowerCase();

    return (
      <>
        <CFormSelect size="sm" defaultValue={language} className="mb-3" onChange={(e) => { updateCallQueueConfiguration({ voiceAppId: voiceAppId, language: e.target.value }) }}>
          <option value="de-de">Deutsch</option>
          <option value="en-us">English</option>
          <option value="fr-fr">Fran&ccedil;ais</option>
          <option value="it-it">Italiano</option>
          <option value="es-es">Spanish</option>
        </CFormSelect>

        {/* 
        No onchange handler. We can use that component later, if an onchange handler was implemented
        
        <CDropdown variant="btn-group"  onChange={(e) => {updateCallQueueConfiguration(callQueue, "language")}}>
          <CDropdownToggle size="sm">Language</CDropdownToggle>
          <CDropdownMenu>
          <CDropdownItem active={selectedLanguage === "de-de" ? true : false}><CIcon icon={cifDe} size="l" /> Deutsch</CDropdownItem>
          <CDropdownItem active={selectedLanguage === "en-us" ? true : false}><CIcon icon={cifUs} size="l" /> English</CDropdownItem>
          <CDropdownItem active={selectedLanguage === "fr-fr" ? true : false}><CIcon icon={cifFr} size="l" /> Fran&ccedil;ais</CDropdownItem>
            <CDropdownItem active={selectedLanguage === "it-it" ? true : false}><CIcon icon={cifIt} size="l" /> Italiano</CDropdownItem>
          </CDropdownMenu>
        </CDropdown> */}
      </>)
  }

  const RenderCallQueueItem = (item) => {
    const selectedChannel = {
      channelId: item.channelId,
      teamsId: item.teamsId
    }

    return (
      <CAccordionItem key={"row-" + item.voiceAppId} itemKey={"row-" + item.voiceAppId}>
        <CAccordionHeader>{GetVoiceAppType(item.voiceAppType)}&nbsp;&nbsp;{item.voiceAppName}</CAccordionHeader>
        <CAccordionBody>
          <CRow className="tcf-acc-voiceapp">
            <CCol xs={4}>{t("Description")}:</CCol>
            <CCol xs={8}><CFormTextarea rows={1} defaultValue={item.description} onBlur={(e) => updateCallQueueConfiguration({ voiceAppId: item.voiceAppId, description: (e.target as HTMLTextAreaElement).value })} ></CFormTextarea></CCol>
          </CRow>
          <CRow className="tcf-acc-voiceapp">
            <CCol xs={4}>{t("TeamsChannel")}:</CCol>
            <CCol xs={8}>
              <EasyTeamsChannelPicker voiceAppId={item.voiceAppId} selectedTeams={selectedChannel.teamsId} selectedChannel={selectedChannel.channelId} onOptionSelect={onOptionSelect.bind(this, item)}></EasyTeamsChannelPicker>
            </CCol>
          </CRow>
          <CRow className="tcf-acc-voiceapp">
            <CCol xs={4}>{t("EnableAnsweredCalls")}:</CCol>
            <CCol xs={8}><CFormSwitch onClick={(e) => updateCallQueueConfiguration({ voiceAppId: item.voiceAppId, showAnsweredCalls: (e.target as HTMLInputElement).checked })} defaultChecked={item.showAnsweredCalls} /></CCol>
          </CRow>
          {easyContext.productLicenses?.EasyCallReport.planType === "Professional" || easyContext.productLicenses?.EasyCallReport.planType === "Enterprise" &&
            <>
              <CRow className="tcf-acc-voiceapp">
                <CCol xs={4}>{t("EnableNotifications")}:</CCol>
                <CCol xs={8}><CFormSwitch onClick={(e) => updateCallQueueConfiguration({ voiceAppId: item.voiceAppId, showNotificationInChannel: (e.target as HTMLInputElement).checked })} defaultChecked={item.showNotificationInChannel} /></CCol>
              </CRow>
              <CRow className="tcf-acc-voiceapp">
                <CCol xs={4}>{t("AvailableTags")}:</CCol>
                <CCol xs={8}>
                  <CFormInput onKeyDown={(e) => { if (e.key === 'Enter') { handleAvailableTag(item.voiceAppId, { id: uuid(), label: (e.target as HTMLTextAreaElement).value }, "add"); (e.target as HTMLTextAreaElement).value = ""; } }} text={t("AvailableTagsDescription")} />
                  <div className="tcf-available-tags">
                    {item.availableTags?.map((tag) => {
                      return <><CButton color="light" size="sm" onClick={() => handleAvailableTag(item.voiceAppId, tag, "delete")}>{tag.label} <CIcon icon={cilX} /> </CButton>&nbsp;</>
                    })}
                  </div>
                </CCol>
              </CRow>
            </>
          }
          <CRow className="tcf-acc-voiceapp">
            <CCol xs={4}>{t("Language")}:</CCol>
            <CCol xs={8}>{GetFlags(item.voiceAppId, item.language)}</CCol>
          </CRow>
          <CRow className="tcf-acc-voiceapp">
            <CCol xs={4}>{t("Timezone")}:</CCol>
            <CCol xs={8}>
              <CFormSelect size="sm" className="mb-3" defaultValue={item.timeZone} onChange={(e) => { updateCallQueueConfiguration({ voiceAppId: item.voiceAppId, timeZone: e.target.value }) }}>
                {
                  timezones.map((timezone) => {
                    // if (item.timeZone !== undefined && item.timeZone !== null && item.timeZone !== "" && timezone.code === item.timeZone) {
                    //   return (<option selected key={timezone.code} value={timezone.code}>{timezone.timezone}</option>)
                    // }
                    // else if ((item.timeZone === undefined || item.timeZone === null || item.timeZone === "") && timezone.code === "W. Europe Standard Time") {
                    //   return (<option selected key={timezone.code} value={timezone.code}>{timezone.timezone}</option>)
                    // }
                    // else {
                      return (<option key={timezone.code} value={timezone.code}>{timezone.timezone}</option>);
                    // }
                  })
                }
              </CFormSelect></CCol>
          </CRow>
          {configuration.enablePlanner && (easyContext.productLicenses?.EasyCallReport.planType === "Professional" || easyContext.productLicenses?.EasyCallReport.planType === "Enterprise") && <CRow className="tcf-acc-voiceapp">
            <CCol xs={4}>{t("Planner")}:</CCol>
            <CCol xs={8}>
              <EasyPlannerPicker update={(planId, bucketId) => {
                updateCallQueueConfiguration({
                  voiceAppId: item.voiceAppId,
                  plannerConfig: {
                    planId,
                    bucketId
                  }
                })
              }
              } teamsId={item.teamsId} planId={item?.plannerConfig?.planId} bucketId={item?.plannerConfig?.bucketId}></EasyPlannerPicker>
            </CCol>
          </CRow>}
          <CButton color="danger" variant="outline" onClick={() => RemoveCallQueue(item.voiceAppId)}>{t("Delete")}</CButton>
        </CAccordionBody>
      </CAccordionItem>
    );
  };

  const RenderCallQueueItems = () => {
    // Render configured assignments
    const items = configuration?.teamsAssignments?.map((item) => (
      RenderCallQueueItem(item)
    ));

    return (items ?
      <CCard>
        <CCardBody>
          <h5>{t("CallQueueConfigTitle")}</h5>
          <p>{t("CallQueueConfigDescription")}</p>
          {IsLicenseLimitReached ? <><CTooltip content={t("NoLicenses")} placement="left"><CLink>
            <Select options={groupedVoiceApps} onChange={OnSelectedCallQueue} className="tcf-callqueue-select" isSearchable isClearable isDisabled={IsLicenseLimitReached} /></CLink></CTooltip><br /></>
            : <><Select options={groupedVoiceApps} onChange={OnSelectedCallQueue} className="tcf-callqueue-select" isSearchable isClearable isDisabled={IsLicenseLimitReached} /><br /></>
          }
          <CAccordion flush alwaysOpen >
            {items}
          </CAccordion>
        </CCardBody>
      </CCard > : <></>
    );
  }

  return (isAuthenticated && easyContext.productLicenses?.EasyCallReport?.isLicensed ? (
    <div>
      <br />
      {isPageConfigLoading ?
        <div className="d-flex justify-content-center tcf-loading-background">
          <CSpinner variant="grow" />&nbsp;&nbsp;&nbsp;<h5>{loadingMsg}</h5>
        </div> : <></>
      }

      <CToaster className="p-3" placement="top-end" push={toast} ref={toaster} />
      <CImage fluid src={EasyCallReportLogo} alt="Easy Call Report Configuration" width={370} /><br /><br />
      <p>
        {t("Introduction")}
      </p>
      <CRow>
        <CCol xs={6}>
          <CWidgetStatsA
            className="mb-4"
            color="light"
            value={
              <>
                {callsLastMonth + ' '}
                {/* <span className="fs-6 fw-normal">
                  (40.9% <CIcon icon={cilArrowTop} />)
                </span> */}
              </>
            }
            title="Calls last 30 days"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: '70px' }}
                data={{
                  labels: Object.keys(callsGroupedByCW),
                  datasets: [
                    {
                      label: 'Calls grouped by CWs',
                      backgroundColor: 'rgba(74,53,206,.5)',
                      borderColor: 'rgba(74,53,206,.7)',
                      data: Object.values(callsGroupedByCW).map((item) => {
                        return item.length;
                      }),
                      fill: true,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
          <CWidgetStatsB
            className="mb-3"
            progress={{ color: 'success', value: answeredCallsPercent }}
            text="Answered Calls of the configured voice apps"
            title="Answered Calls"
            value={Math.round(answeredCallsPercent) + "%"}
          />
          <CWidgetStatsB
            className="mb-3"
            progress={{ color: 'danger', value: highestCQMissedPercent }}
            text="Voice App with most missed calls"
            title={highestCQName}
            value={highestCQMissedCount + " missed calls"}
          />
        </CCol>
        <CCol>
          <CCard className="tcf-panel-card">
            <CCardImage orientation="top" src={LicenseImg} className="tcf-card-img" />
            <CCardBody>
              <CCardTitle>{voiceAppCount + " / " + easyContext.productLicenses?.EasyCallReport?.seatCount} {t("LicensesTitle")}</CCardTitle>
              <CCardText>
                {t("LicensesDescription")}<br /><br /><br />
              </CCardText>
              <CButton href="/subscriptions" color="dark">
                <CIcon icon={cilLoopCircular} />
                &nbsp;&nbsp;{t("LicensesButton")}
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol>
          <CCard className="tcf-panel-card">
            <CCardImage orientation="top" src={SupportImg} className="tcf-card-img" />
            <CCardBody>
              <CCardTitle>{t("SupportTitle")}</CCardTitle>
              <CCardText>
                {t("SupportDescription")}<br /><br />
                <CLink href="https://docs.easycallreport.easyplatform.app/getting-started/easy-call-report-configuration" target="_blank">Easy Call Report Configuration Guide</CLink><br /><br />
              </CCardText>
              <CButton href="mailto:support@twincapfirst.ch?subject=Easy%20Call%20Report" target="_blank" color="dark">
                <CIcon icon={cilPhone} />
                &nbsp;&nbsp;{t("SupportButton")}
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>

        <CModal
          backdrop="static"
          visible={oldEcrModalVisible}
          alignment="center"
          onClose={() => setOldEcrModalVisible(false)}
          aria-labelledby="Close">
          <CModalHeader closeButton={false}>
            <CModalTitle>New Release 2.1</CModalTitle>
          </CModalHeader>
          <CModalBody>
            We&apos;ve updated Easy Call Report to version 2.1. The update works semi-automatic. Since you visited this page, you were migrated automatically. You just need to update the consent of the Easy Platform Configuration Center.
            <br />
            <br />
            <h5>Changelog</h5>
            <CListGroup flush>
              <CListGroupItem><b>New</b></CListGroupItem>
              <CListGroupItem>Automatic Teams App Installation</CListGroupItem>
              <CListGroupItem>CQs / AAs don&apos;t need the same display name as the associated resource account anymore</CListGroupItem>
              <CListGroupItem>Tenant call record connection works automatically</CListGroupItem>
              <CListGroupItem>KPIs in Configuration Center</CListGroupItem>
              <CListGroupItem>Status notifications are displayed as toasts</CListGroupItem>
              <CListGroupItem>Call record updates are consided</CListGroupItem>
              <CListGroupItem><b>Fixed Bugs</b></CListGroupItem>
              <CListGroupItem>Fixed a bug, where call records were not reported correctly</CListGroupItem>
              <CListGroupItem>Lookup results were not displayed in Call History</CListGroupItem>
              <CListGroupItem><b>Known Issues</b></CListGroupItem>
              <CListGroupItem>Call cards not updating in some tenants (Microsoft is investigating that)</CListGroupItem>
              <CListGroupItem>We sometimes still get delayed, incorrect or duplicated call records from Microsoft. We are working on a solution together with the Microsoft product management.</CListGroupItem>
            </CListGroup>
            <br /><br />
          </CModalBody>
          <CModalFooter>
            <CButton color="success" onClick={() => { window.open('https://login.microsoftonline.com/common/adminconsent?client_id=' + ProductConfiguration.EasyCallReport.Admin.ApplicationId); updateConfiguration({ version: "2.1" } as Partial<EasyCallReportConfiguration>); setOldEcrModalVisible(false) }}>
              <CIcon icon={cilLoopCircular} />
              &nbsp;&nbsp;{t("ConsentButton")}
            </CButton>
          </CModalFooter>
        </CModal>
      </CRow>
      <br />
      <CCard className="p-8">
        <CCardBody>
          <CCardTitle>{t("FeaturesTitle")}</CCardTitle>
          <CRow>
            <CCol sm={8}>
              <CCardText>
                {t("FeaturesDescription")}
              </CCardText>
              <CFormSwitch label={t("EnableAnalytics")} id="enableAnalytics" defaultChecked={configuration?.enableAnalytics} onClick={(e) => updateConfiguration({ enableAnalytics: (e.target as HTMLInputElement).checked } as Partial<EasyCallReportConfiguration>)} />
              <CFormSwitch label={t("EnableTelemetry")} id="enableTelemetry" defaultChecked={configuration?.enableTelemetry} onClick={(e) => updateConfiguration({ enableTelemetry: (e.target as HTMLInputElement).checked } as Partial<EasyCallReportConfiguration>)} />

              <CFormSwitch disabled={(easyContext.productLicenses?.EasyCallReport.planType !== "Professional" && easyContext.productLicenses?.EasyCallReport.planType !== "Enterprise")} label={<>{t("EnablePlannerIntegration")} {(easyContext.productLicenses?.EasyCallReport.planType !== "Professional" && easyContext.productLicenses?.EasyCallReport.planType !== "Enterprise") ? <CTooltip
                content={t("ProLicenseRequired")}
                trigger={['hover', 'focus']}
                placement="bottom"
              ><b>ℹ</b></CTooltip> : <></>}</>} id="enablePlanner" defaultChecked={configuration?.enablePlanner && (easyContext.productLicenses?.EasyCallReport.planType === "Professional" || easyContext.productLicenses?.EasyCallReport.planType === "Enterprise")} onClick={(e) => {
                updateConfiguration({ enablePlanner: (e.target as HTMLInputElement).checked } as Partial<EasyCallReportConfiguration>);
                if ((e.target as HTMLInputElement).checked) {
                  window.open(' https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
                    'client_id=' + ProductConfiguration.EasyCallReport.ApplicationId +
                    '&scope=Tasks.ReadWrite' +
                    '&redirect_uri=https://teams.easycallreport.easyplatform.app/popup-close.html' +
                    '&response_type=code' +
                    '&response_mode=query', 'popup', 'width=600,height=600');
                }
              }} />

              <br />
              <h5>{t("IntegrationTitle")}</h5>
              <CFormSwitch label={t("EnableAzureADSearch")} id="enableAzureADSearch" defaultChecked={configuration?.enableAzureADSearch} onClick={(e) => updateConfiguration({ enableAzureADSearch: (e.target as HTMLInputElement).checked } as Partial<EasyCallReportConfiguration>)} />
              <br />
              <CFormSwitch label={t("EnableSharedMailboxSearch")} id="enableSharedMailboxSearch" defaultChecked={configuration?.enableSharedMailboxSearch} onClick={(e) => updateConfiguration({ enableSharedMailboxSearch: (e.target as HTMLInputElement).checked } as Partial<EasyCallReportConfiguration>)} />
              <CFormInput type="text" size="sm" floatingLabel={t("SharedMailboxAddress")} defaultValue={configuration?.sharedMailboxAddress} onBlur={(e) => updateConfiguration({ sharedMailboxAddress: (e.target as HTMLInputElement).value } as Partial<EasyCallReportConfiguration>)} />
              <br />
              <CFormSwitch label={t("EnableSearchCh")} id="enableSearchCh" defaultChecked={configuration?.enableSearchChIntegration} onClick={(e) => updateConfiguration({ enableSearchChIntegration: (e.target as HTMLInputElement).checked } as Partial<EasyCallReportConfiguration>)} />
              <CFormInput type="text" size="sm" floatingLabel="Search.ch API Key" defaultValue={configuration?.searchChApiKey} onBlur={(e) => updateConfiguration({ searchChApiKey: (e.target as HTMLInputElement).value } as Partial<EasyCallReportConfiguration>)} />
              {/* <br />              
              <CFormSwitch label={t("EnableEasyLookup")} id="enableEasyLookup" defaultChecked={configuration.enableEasyLookup} onClick={(e) => updateConfiguration("enableEasyLookup", (e.target as HTMLTextAreaElement).checked)} />
            <CFormInput type="text" size="sm" floatingLabel="Easy Lookup Url" defaultValue={configuration?.easyLookupUrl} onBlur={(e) => updateConfiguration("easyLookupUrl", (e.target as HTMLTextAreaElement).value)} /> */}
            </CCol>
            <CCol sm={4}>
              <ReactPlayer url='https://www.youtube.com/watch?v=RSejhkYoCpA' pip={true} playsinline={true} controls={false} width='100%' height='225px' />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <br />
      {RenderCallQueueItems()}
      <br />
      <br />
      <br />
      <br />
    </div>) : (
    <div className="d-flex justify-content-center tcf-loading-background">
      <CSpinner variant="grow" />&nbsp;&nbsp;&nbsp;<h5>{loadingMsg}</h5>
    </div>)

    // : <div>
    //   <br />
    //   <CImage fluid src={EasyCallReportLogo} alt="Easy Call Report Configuration" width={370} /><br /><br /><br />
    //   <CCard>
    //     <CCardBody>
    //       <CCardTitle>Easy Call Report is not licensed</CCardTitle>
    //       <CCardText>
    //         We would be happy, if you want to use our Product Easy Call Report. You can buy license through our <CLink target="_blank" href="https://twincapfirst.ch/teams-easy-call-report-en/">website</CLink> or in the <CLink target="_blank" href="https://appsource.microsoft.com/en-us/product/SaaS/twincapfirstag1583404600025.easycallreport">Microsoft Store</CLink>.<br /><br />
    //         <CButton href="https://twincapfirst.ch/teams-easy-call-report-en/" target="_blank" color="light">
    //           Easy Call Report Website
    //         </CButton>&nbsp;&nbsp;&nbsp;
    //         <CButton href="https://appsource.microsoft.com/en-us/product/SaaS/twincapfirstag1583404600025.easycallreport" target="_blank" color="light">
    //           Microsoft Store
    //         </CButton>
    //       </CCardText>
    //     </CCardBody>
    //   </CCard>
    // </div>
  )
}

export default EasyCallReport
export const APIM = 'https://api.easyplatform.app/'
export const Version = "2.5"

const DefaultRedirectUrl = 'https://admin.beta.easyplatform.app/';

export const ProductConfiguration = {
    AdminPortal: {
        ApplicationId: "ab075ccd-637d-41b8-b6c4-632d75ee771a",
        Scopes: [ 'User.Read', 'Application.Read.All' ],
        RedirectUrl: DefaultRedirectUrl,
    },
    EasyCallReport: {
        ApplicationId: "c42b0067-bfb5-4a33-8f9f-5f1ef2dfceb8",
        Scopes: ['Channel.ReadBasic.All', 'Group.Read.All'],
        RedirectUrl: 'https://teams.easycallreport.easyplatform.app/popup-close.html',
        NotificationUrl: 'https://api.easyplatform.app/easycallreport/callrecord',
        Admin: {
            ApplicationId: "1825119b-4293-4a92-8a45-24ba908a24ed",
            Scopes: ['https://graph.microsoft.com/.default'],
            ElevatedScope: '48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation',
            RedirectUrl: DefaultRedirectUrl,
        }
    },
    EasyDirectory: {
        ApplicationId: "1a9a6a25-b2c3-4b56-85ff-4b72687073f1",
        Scopes: ['Channel.ReadBasic.All', 'Group.Read.All'],
        RedirectUrl: DefaultRedirectUrl,
        Admin: {
            ApplicationId: "f1e99ba1-33ba-4dcf-825a-305546cdd084",
            Scopes: ['https://graph.microsoft.com/.default'],
            RedirectUrl: DefaultRedirectUrl,
        }
    }
}
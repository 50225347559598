import { InteractionType, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated, useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { CButton, CCard, CCardBody, CCardImage, CCardTitle, CContainer } from "@coreui/react";
import { useEffect, useState, Suspense, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "../../routes";
import { ConfigurationService } from "../../services/ConfigurationService";
import { SubscriptionService } from "../../services/SubscriptionService";
import AppFooter from "../AppFooter";
import AppHeader from "../AppHeader";
import { EasyContext } from "./EasyContext"
import { Login, Providers, ProviderState, SimpleProvider } from "@microsoft/mgt-react";

import TcfImg from '../../assets/images/twincap_first_ag_cover.jpg'
import { EasyProductLicenses } from "../../common/interfaces";

const EasyContextHandler = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [subscriptions, setSubscriptions] = useState([]);
    const [accessToken, setAccessToken] = useState("");
    const [tenantId, setTenantId] = useState("");
    const [userId, setUserId] = useState("");
    const [productLicenses, setProductLicenses] = useState<EasyProductLicenses>();
    const [userDisplayName, setUserDisplayName] = useState("");

    const request = {
        scopes: ["User.Read"]
    }

    const { login, error } = useMsalAuthentication(InteractionType.Redirect, request);

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, request);
        }
    }, [error]);

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            Providers.globalProvider = new SimpleProvider((scopes: string[]) => {
                return instance.acquireTokenSilent({
                    account: accounts[0],
                    scopes: scopes
                }).then(response => {
                    return response.accessToken
                });
            });

            Providers.globalProvider.setState(ProviderState.SignedIn)

            instance.acquireTokenSilent({
                account: accounts[0],
                scopes: ["User.Read"]
            }).then(response => {
                setAccessToken(response.accessToken);
                setTenantId(response.tenantId);
                setUserDisplayName(response.account.name);
                setUserId(response.account.username);

                const subscriptionService = new SubscriptionService(response.accessToken);
                subscriptionService.getSubscriptions().then((subscriptions) => {
                    setSubscriptions(subscriptions);

                    const EasyCallReportInfo = subscriptionService.getEasyCallReportLicenseInfo(subscriptions);
                    const EasyDirectoryInfo = subscriptionService.getEasyDirectoryLicenseInfo(subscriptions);

                    setProductLicenses(
                        {
                            EasyCallReport: EasyCallReportInfo,
                            EasyDirectory: EasyDirectoryInfo
                        }
                    )
                }).catch((error) => {
                    console.log(error);
                });
            })
        }
    }, [inProgress, isAuthenticated, accounts, instance]);

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <AuthenticatedTemplate>
                <EasyContext.Provider value={{ accessToken, productLicenses, subscriptions, tenantId, userDisplayName, userId }}>
                    <AppHeader />
                    <div className="body flex-grow-1 px-3">
                        <CContainer lg>
                            <Suspense>
                                <Routes>
                                    {routes.map((route, idx) => {
                                        return (
                                            route.element && (
                                                <Route
                                                    key={idx}
                                                    path={route.path}
                                                    element={<route.element />}
                                                />
                                            )
                                        )
                                    })}
                                    <Route path="/" element={<Navigate to="dashboard" replace />} />
                                </Routes>
                            </Suspense>
                        </CContainer>
                    </div>
                    <AppFooter />
                </EasyContext.Provider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                    <AppHeader />
                    <br />
                    <div className="body flex-grow-1 px-3">
                        <CContainer lg>
                            <CCard>
                                <CCardImage orientation="top" src={TcfImg} className="tcf-card-img" />
                                <CCardBody>
                                    <CCardTitle>Easy Platform Login</CCardTitle>
                                    <CButton onClick={() => login(InteractionType.Redirect, request)}>Login</CButton>
                                </CCardBody>
                            </CCard>
                        </CContainer>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </div>

    )
}

export default EasyContextHandler
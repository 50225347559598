import { PublicClientApplication } from "@azure/msal-browser";
import { ProductConfiguration } from '../common/constants';
import EasyContextHandler from '../components/context/EasyContextHandler';
import { MsalProvider } from "@azure/msal-react";
import { Providers, SimpleProvider } from "@microsoft/mgt-element";
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';

const msalConfigurationCenterConfig = {
  auth: {
    clientId: ProductConfiguration.AdminPortal.ApplicationId,
    redirectUri: ProductConfiguration.AdminPortal.RedirectUrl,
  },
};

const configurationCenterPca = new PublicClientApplication(msalConfigurationCenterConfig);

const DefaultAuthenticatedLayout = () => {
  return (
    <MsalProvider instance={configurationCenterPca}>
      <EasyContextHandler />
    </MsalProvider>
  )
}

export default DefaultAuthenticatedLayout
